@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,300&display=swap');

body {
  background-color: #232830 !important; 
  color: white !important;
  overflow: hidden;
}
h1 {
  font-family: 'Paytone One', sans-serif;
  font-size: 45px;
  color: white !important;
}
.login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90vw;
}
.login-container .small-text {
  margin-top: -30px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-style: italic;
}
.ssp {
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
}
.link {
  color: #40A9FF !important;
  cursor: pointer;
}
.logo {
  height: 32px;
  margin: 16px;
  font-size: 20px;
  font-family: 'Paytone One', sans-serif;
}
.addLocBody {
  overflow: scroll;
  max-height: 100px;
}
[title="Location Name"], [title="Address/Coordinates"], [title="Images"], [title="Description"], [title="Type"] {
  color: #DEDEDE !important;
}
.ant-modal-header {
  background-color: #1F1F1F !important;
  border-bottom: 1px solid #303030 !important;
  font-family: 'Paytone One', sans-serif !important;
}
.ant-modal-title, .ant-modal-close {
  color: #DEDEDE !important;
}
.ant-modal-footer {
  background-color: #1F1F1F !important;
  border-top: 1px solid #303030 !important;
}
.ant-modal-footer .ant-btn {
  color: #DEDEDE !important;
}
.ant-tabs-tab-btn {
  margin-top: 15px !important;
  color: #dedede !important;
}
[aria-selected="true"] {
  color: #40A9FF !important;
}
.ant-tabs-nav-wrap {
  border-bottom: 1px solid #303030 !important;
}
.ant-menu {
  border-top: 1px solid #303030 !important;
}
.query {
  padding: 10px;
  margin-top: 2px;
}
.credit {
  color: #b0b0b0;
  margin-top: 10px;
  font-size: 10px;
  margin-bottom: -15px;
}
.form-input::placeholder {
  color: #4d4d4d !important;
}
.ant-radio-button-wrapper {
  background-color: #4d4d4d !important;
  border-color: #000 !important;
  color: #dedede !important;
}
.ant-radio-button-wrapper::before {
  background-color: #000 !important;
}
.ant-radio-button-checked {
  background-color: #40A9FF !important;
}
input[type='radio']:checked:after {
  border-color: red !important;
  background-color: red !important;
}
.add-location-image {
  cursor: pointer !important;
}
.ant-dropdown-menu, .ant-dropdown-menu::before {
  background-color: #1F1F1F !important;
  color: #dedede !important;
}
.ant-dropdown-menu-title-content, .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu-arrow-icon {
  color: #dedede !important;
}
.ant-dropdown-menu-item-divider {
  background-color: #4d4d4d !important;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background: #4d4d4d !important;
}
.logout-btn {
  color: #d15a5a !important;
}
.location-type-dropdown {
  border: 1px solid #434343 !important;
}
.slick-arrow {
  color: red !important;
}
.results {
  margin-top: 0px !important;
  margin-bottom: -5px !important;
  font-weight: lighter;
}
/* tile uploaded pictures */
.comment-action {
  padding-left: 8px;
  cursor: 'auto';
}

[class*='-col-rtl'] .comment-action {
  padding-right: 8px;
  padding-left: 0;
}

.ant-dropdown-menu-item {
  background-color: #1F1F1F !important;
}

.coord-icon, .description-icon {
  color: #dedede !important;
}

.location-body-section {
  padding: 5px;
  /*background-color: #4d4d4d;*/
  background-color: rgba(255,255,255,.075);
  border-radius: 2px;
}